import {EditModel} from "backoffice/ui/Pages/LoanManager/Exchange/Form/Trade.tsx";
import Big from "big.js";
import {ExchangeStatus, LoanTradeType} from "src/codes";
import {PlazaI} from "src/utils/plazai";

interface JournalType {
  principal?: {
    FC: Big
    JPY: number
    profit: number
  },

  interest?: {
    FC: Big
    JPY: number
  }
}

export function convert(model: EditModel): string {
  const journal    = summary(model),
        miscAmount = model.miscAmount ?? 0;

  const results: PlazaI.Row[] = [];

  if (journal.interest) {
    results.push({
      separator: false,
      use      : 0,
      date     : model.processDate,
      outline  : '',
      debit    : {
        group    : 'R150',
        item     : '180010000',
        taxType  : '0',
        taxRate  : '0',
        customer : '',
        project  : '',
        currency : '',
        rate     : '',
        fc       : '',
        jpy      : String(journal.interest.JPY + Number(miscAmount)),
        tax      : '',
        reference: '両替結果',
      },
      summary  : '分配償還用両替/利息/両替結果'
    });

    results.push({
      separator: false,
      use      : 0,
      date     : model.processDate,
      outline  : '',
      credit   : {
        group    : 'R150',
        item     : '180010000',
        taxType  : '0',
        taxRate  : '0',
        customer : '',
        project  : '',
        currency : 'USD',
        rate     : '',
        fc       : journal.interest.FC.toFixed(2),
        jpy      : String(journal.interest.JPY),
        tax      : '',
        reference: '両替額/ﾌｧﾝﾄﾞ受取額',
      },
      summary  : '分配償還用両替/利息/両替結果'
    });

    results.push({
      separator: false,
      use      : 0,
      date     : model.processDate,
      outline  : '',
      credit   : {
        group    : 'R100',
        item     : '800020000',
        taxType  : '0',
        taxRate  : '0',
        customer : '20170799999',
        project  : '',
        currency : '',
        rate     : '',
        fc       : '',
        jpy      : String(miscAmount),
        tax      : '',
        reference: '雑益',
      },
      summary  : '分配償還用両替/利息/雑益'
    });

    results.push({
      separator: false,
      use      : 0,
      date     : model.processDate,
      outline  : '',
      debit    : {
        group    : 'R100',
        item     : '150010006',
        taxType  : '0',
        taxRate  : '0',
        customer : '20170799999',
        project  : '',
        currency : '',
        rate     : '',
        fc       : '',
        jpy      : String(miscAmount),
        tax      : '',
        reference: '雑益',
      },
      summary  : '分配償還用両替/利息/雑益 (未収未払)'
    });

    results.push({
      separator: false,
      use      : 0,
      date     : model.processDate,
      outline  : '',
      credit   : {
        group    : 'R150',
        item     : '340010002',
        taxType  : '0',
        taxRate  : '0',
        customer : '20170799999',
        project  : '',
        currency : '',
        rate     : '',
        fc       : '',
        jpy      : String(miscAmount),
        tax      : '',
        reference: '雑益',
      },
      summary  : '分配償還用両替/利息/雑益 (未収未払)'
    });
  }

  if (journal.principal) {
    results.push({
      separator: false,
      use      : 0,
      date     : model.processDate,
      outline  : '',
      debit    : {
        group    : 'R150',
        item     : '180010000',
        taxType  : '0',
        taxRate  : '0',
        customer : '',
        project  : '',
        currency : '',
        rate     : '',
        fc       : '',
        jpy      : String(journal.principal.JPY),
        tax      : '',
        reference: '両替結果',
      },
      summary  : '分配償還用両替/元本/両替結果'
    });

    results.push({
      separator: false,
      use      : 0,
      date     : model.processDate,
      outline  : '',
      credit   : {
        group    : 'R150',
        item     : '180010000',
        taxType  : '0',
        taxRate  : '0',
        customer : '',
        project  : '',
        currency : 'USD',
        rate     : '',
        fc       : journal.principal.FC.toFixed(2),
        jpy      : String(journal.principal.JPY - journal.principal.profit),
        tax      : '',
        reference: '両替額*取得時ﾚｰﾄ平均',
      },
      summary  : '分配償還用両替/元本/両替結果'
    });

    results.push({
      separator: false,
      use      : 0,
      date     : model.processDate,
      outline  : '',
      credit   : {
        group    : 'R150',
        item     : '310010001',
        taxType  : '0',
        taxRate  : '0',
        customer : '20170799999',
        project  : '',
        currency : '',
        rate     : '',
        fc       : '',
        jpy      : String(0 < journal.principal.profit ? journal.principal.profit : 0),
        tax      : '',
        reference: '為替差益',
      },
      summary  : '分配償還用両替/元本/為替差益'
    });

    results.push({
      separator: false,
      use      : 0,
      date     : model.processDate,
      outline  : '',
      debit    : {
        group    : 'R150',
        item     : '310010000',
        taxType  : '0',
        taxRate  : '0',
        customer : '20170799999',
        project  : '',
        currency : '',
        rate     : '',
        fc       : '',
        jpy      : String(0 > journal.principal.profit ? Math.abs(journal.principal.profit) : 0),
        tax      : '',
        reference: '為替差損',
      },
      summary  : '分配償還用両替/元本/為替差損'
    });
  }

  if (results[0]) {
    results[0].separator = true;
  }
  return PlazaI.csv(results).join("\r\n");
}


/**
 * 仕訳情報を生成する
 *
 * @param model
 */
function summary(model: EditModel): JournalType {
  const journal: JournalType = {
    principal: {
      FC    : Big(0),
      JPY   : 0,
      profit: 0,

    },

    interest: {
      FC : Big(0),
      JPY: 0,
    }
  };

  for (const trade of model.targets) {
    for (const detail of trade.details) {

      let principal = false;
      switch (detail.typeId) {
        case LoanTradeType['融資'].id:
        case LoanTradeType['貸倒金'].id:
        case LoanTradeType['手数料'].id:
        case LoanTradeType['代理受領分(IL)'].id:
        case LoanTradeType['代理受領分(PC)'].id:
        case LoanTradeType['為替差損'].id:
          continue;

        case LoanTradeType['元本返済'].id:
          principal = true;
          break;

        case LoanTradeType['利息'].id:
        case LoanTradeType['遅損金'].id:
        case LoanTradeType['利息（営業者報酬なし）'].id:
        case LoanTradeType['遅損金（営業者報酬なし）'].id:
          break;
      }

      // 集計
      const {FC, JPY, profit} = detail.dividends.reduce(({FC, JPY, profit}, dividend) => {
        if (dividend.exchangeStatusId === ExchangeStatus['完了'].id) {
          FC = FC.plus(dividend.amount);
          JPY += dividend.exchangeResult?.toNumber() ?? 0;
          profit += dividend.exchangeProfit?.toNumber() ?? 0;
        }
        return {FC, JPY, profit};
      }, {FC: Big(0), JPY: 0, profit: 0});

      // 元本
      if (principal) {
        if (!journal.principal) {
          journal.principal = {
            FC    : Big(0),
            JPY   : 0,
            profit: 0,
          };
        }
        journal.principal.FC = journal.principal.FC.add(FC);
        journal.principal.JPY += JPY;
        journal.principal.profit += profit;

      }
      // 利息
      else {
        if (!journal.interest) {
          journal.interest = {
            FC : Big(0),
            JPY: 0,
          };
        }
        journal.interest.FC = journal.interest.FC.add(FC);
        journal.interest.JPY += JPY;
      }
    }
  }
  return journal;
}
