import {useTRPC} from "backoffice/ui/App";
import {ConfirmModal, Footer, FormCol, FormNumber, FormText, FormTextArea, useLoader, useMessage} from "backoffice/ui/Components";
import React, {FC, useEffect, useState,} from "react";
import {Breadcrumb, Button, Col, Container, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip} from "react-bootstrap";
import {useNavigate, useParams} from "react-router";
import {Currency, ExchangeCategory} from "src/codes";
import {useImmer} from "use-immer";
import {useImmerChangeHandler} from "backoffice/ui/utils/react/hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator} from "@fortawesome/free-solid-svg-icons";
import Big from "big.js";
import {Overwrite} from "backoffice/api/types";
import {ExchangeFundBase, ExchangeTaskType} from "backoffice/api/AppRouter/Loan/Exchange/Fund";
import {Link} from "react-router-dom";


type EditModel = Overwrite<ExchangeTaskType, {
  executeDate: string
  executeAmount: string
  processDate: string
  resultAmount: string
  rate: string
}>

export const LoanExchangeFundFormPage: FC = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        tRPC     = useTRPC();


  const [model, setModel] = useImmer<EditModel | null>(null),
        [modal, setModal] = useState('');


  // ハンドラ
  const handleChange = useImmerChangeHandler(setModel);

  // ID取得
  const {id} = useParams();

  // データ取得
  useEffect(() => {
    Loader.task(async () => await tRPC.loan.exchange.fund.get.query({id: Number(id)}), 500)
          .then(model => {
            setModel({
              ...model,
              executeDate  : model.executeDate?.toYmd() || '',
              executeAmount: model.executeAmount?.toString() || '',
              processDate  : model.processDate?.toYmd() || '',
              resultAmount : model.resultAmount?.toString() || '',
              rate         : model.rate?.toString() || '',
            });
          })
          .catch(err => Message.error(err, () => Navigate('../')));
  }, []);

  // ロード前は表示なし
  if (model === null) {
    return "";
  }

  // 通貨設定
  const curr = {
    source: Currency.JPY,
    target: Currency.find(model?.currencyId)
  };

  const handleExecute = () => {
    Loader.task(async () => {
      try {
        await tRPC.loan.exchange.fund.execute.mutate({
          id           : Number(id),
          executeDate  : model.executeDate,
          executeAmount: model.executeAmount,
          processDate  : model.processDate,
          resultAmount : model.resultAmount,
          rate         : model.rate,
          memo         : model.memo ?? '',
        });
        Message.show('実行登録が完了しました', () => Navigate('../'));

      } catch (err) {
        Message.error(err);
      }
    }, 500).then()
  }

  const handleConfirm = () => {
    Loader.task(async () => {
      try {
        await tRPC.loan.exchange.fund.confirm.mutate({
          id           : Number(id),
          executeAmount: model.executeAmount,
          resultAmount : model.resultAmount,
          rate         : model.rate
        });
        Message.show('承認登録が完了しました', () => Navigate('../'));

      } catch (err) {
        Message.error(err);
      }
    }, 500).then()
  }


  // 為替レートから両替結果を算出
  const calculateResult = () => {
    if (!model) {
      return;
    }

    try {
      const amount = Big(model.executeAmount),
            rate   = Big(model.rate),
            result = amount.div(rate).toFixed(curr.target.precision || 0, Big.roundHalfUp);

      setModel(draft => {
        if (draft === null) {
          return;
        }
        draft.resultAmount = result;
      });

    } catch (err) {
      console.error(err);
    }
  }


  // 両替結果から為替レートを算出
  const calculateRate = () => {
    if (!model) {
      return;
    }

    try {
      const amount = Big(model.executeAmount),
            result = Big(model.resultAmount),
            rate   = (amount.div(result)).toFixed(3)

      setModel(draft => {
        if (draft === null) {
          return;
        }
        draft.rate = rate;
      });

    } catch (err) {
      console.error(err);
    }
  }

  // 融資案件でグルーピング
  const targets = model?.targets.reduce((targets, el) => {
    if (!(el.loanprj_id in targets)) {
      targets[el.loanprj_id] = [el];
    } else {
      targets[el.loanprj_id].push(el);
    }
    return targets;
  }, {} as Record<string, ExchangeFundBase[]>);

  return (
      <>
        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
            <Breadcrumb.Item href={'../'}>両替一覧</Breadcrumb.Item>
            <Breadcrumb.Item active>ファンド両替編集 #{id}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Container className={'mt-5'}>
          <Row>
            <Col xl={{offset: 2, span: 8}}>
              <Tabs defaultActiveKey={model?.isConfirmed ? 'details' : 'summary'}
                    className="mb-3">

                <Tab eventKey="summary" title="申請サマリ">
                  <Container>
                    <Row className={'py-1'}>
                      <FormCol inputId={'categoryId'}
                               title={'区分'}
                               size={{xs: [4, 8], lg: [2, 4]}}>

                        <FormText value={ExchangeCategory.find(model.categoryId || -1).value || '-'}
                                  readOnly/>
                      </FormCol>
                    </Row>

                    <Row className={'py-1'}>
                      <FormCol inputId={'currencyId'}
                               title={'通貨'}
                               size={{xs: [4, 8], lg: [2, 4]}}>

                        <FormText value={curr.target.value}
                                  readOnly/>
                      </FormCol>
                    </Row>

                    <Row className={'py-1'}>
                      <FormCol inputId={'managerId'}
                               title={'営業者'}
                               size={{xs: [4, 8], lg: [2, 4]}}>

                        <FormText value={model.manager.name} readOnly/>
                      </FormCol>
                    </Row>

                    <Row className={'py-1'}>
                      <FormCol inputId={'executeDateReq'}
                               title={'両替希望日'}
                               size={{xs: [4, 8], lg: [2, 4]}}>

                        <FormText value={model.executeDateReq?.toYmd() || '----/--/--'}
                                  readOnly/>
                      </FormCol>
                    </Row>

                    <Row className={'py-1'}>
                      <FormCol inputId={'orderAmount'}
                               title={'両替申請額'}
                               size={{xs: [4, 6], lg: [2, 3]}}>

                        <FormText value={model.orderAmount.toString().withComma}
                                  className={'text-end'}
                                  readOnly/>
                      </FormCol>

                      <Col xs={{span: 2}}
                           className={'ps-0'}>

                        <OverlayTrigger placement={'right'}
                                        overlay={<Tooltip>「両替申請額」を全額、「両替実行額」にコピー</Tooltip>}>
                          <Button disabled={model.isConfirmed}
                                  onClick={
                                    () => setModel(draft => {
                                      if (draft) {
                                        draft.executeAmount = draft?.orderAmount.toString();
                                      }
                                    })}>
                            全額
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    </Row>

                    <Row className={'py-3'}>
                      <hr/>
                    </Row>

                    <Row className={'py-1'}>
                      <FormCol inputId={'executeDate'}
                               title={'両替実行日'}
                               size={{xs: [4, 8], lg: [2, 4]}}>

                        <FormText type={'date'}
                                  name={'executeDate'}
                                  value={model.executeDate}
                                  onChange={handleChange}/>
                      </FormCol>
                    </Row>

                    <Row className={'py-1'}>
                      <FormCol inputId={'processDate'}
                               title={'受渡日'}
                               size={{xs: [4, 8], lg: [2, 4]}}>

                        <FormText type={'date'}
                                  name={'processDate'}
                                  value={model.processDate}
                                  onChange={handleChange}/>
                      </FormCol>
                    </Row>

                    <Row className={'py-1'}>
                      <FormCol inputId={'executeAmount'}
                               title={'両替実行額'}
                               size={{xs: [4, 6], lg: [2, 3]}}>

                        <FormNumber name={'executeAmount'}
                                    value={model.executeAmount || ''}
                                    precision={0}
                                    align={'end'}
                                    onChange={handleChange}
                                    realtime/>

                        <small>
                          <span className={'text-nowrap'}>* 申請額のうち実際に両替を行った額</span>
                        </small>
                      </FormCol>
                    </Row>

                    <Row className={'py-1'}>
                      <FormCol inputId={'resultAmount'}
                               title={'両替結果額'}
                               size={{xs: [4, 6], lg: [2, 3]}}>

                        <FormNumber name={'resultAmount'}
                                    value={model.resultAmount || ''}
                                    precision={curr.target.precision}
                                    align={'end'}
                                    onChange={handleChange}
                                    realtime/>
                      </FormCol>

                      <Col xs={{span: 2}}
                           className={'ps-0'}>
                        <OverlayTrigger placement={'right'}
                                        overlay={<Tooltip>両替結果をベースに為替レートを算出します</Tooltip>}>
                          <Button disabled={model.isConfirmed}
                                  onClick={calculateRate}>
                            <FontAwesomeIcon icon={faCalculator}/>
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    </Row>

                    <Row className={'py-1'}>
                      <FormCol inputId={'rate'}
                               title={'為替レート'}
                               size={{xs: [4, 6], lg: [2, 3]}}>
                        <FormNumber name={'rate'}
                                    value={model.rate || ''}
                                    precision={3}
                                    align={'end'}
                                    onChange={handleChange}/>
                      </FormCol>

                      <Col xs={{span: 2}}
                           className={'ps-0'}>
                        <OverlayTrigger placement={'right'}
                                        overlay={<Tooltip>為替レートをベースに両替結果を算出します</Tooltip>}>

                          <Button disabled={model.isConfirmed}
                                  onClick={calculateResult}>
                            <FontAwesomeIcon icon={faCalculator}/>
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    </Row>

                    <Row className={'py-1'}>
                      <FormCol inputId={'memo'}
                               title={'メモ'}
                               size={{xs: [4, 8], lg: [2, 8]}}>
                        <FormTextArea name={'memo'}
                                      value={model.memo || ''}
                                      onChange={handleChange}/>
                      </FormCol>
                    </Row>
                  </Container>
                </Tab>

                <Tab eventKey="details" title={`詳細`}>
                  <div className={'container small'}>
                    <Table>
                      <thead>
                      <tr>
                        <th scope="col" className={'text-center'}>融資案件</th>
                        <th scope="col" className={'text-center'}>ID</th>
                        <th scope="col" className={'text-start'}>ファンド</th>
                        <th scope="col" className={'text-end'}>申請額</th>
                        <th scope="col" className={'text-end'}>実行額</th>
                        <th scope="col" className={'text-end'}>結果</th>
                      </tr>
                      </thead>

                      <tbody>
                      {Object.entries(targets).map(([_key, group]) =>
                          group.map((target, i) => (
                              <tr key={target.id}>
                                {i === 0 &&
                                 <td className={'text-start'} rowSpan={group.length}>
                                   <Link to={`/loan-manager/project/${target.loanprj_id}`}>
                                     {target.loanprj_name}
                                   </Link>
                                 </td>
                                }

                                <td className={'text-center'}>{target.id}</td>
                                <td className={'text-start'}>{target.name}</td>
                                <td className={'text-end'}>{target.amount.toString().withComma}</td>
                                <td className={'text-end'}>{target.executed?.toString().withComma ?? '-'}</td>
                                <td className={'text-end'}>{target.result?.toString().withComma ?? '-'}</td>
                              </tr>
                          ))
                      )}
                      </tbody>

                      {/* 承認後 */}
                      {model?.isConfirmed && (
                          <tfoot>
                          <tr>
                            <th className={'text-center'} colSpan={3}>{ExchangeCategory.find(model.categoryId || -1).value}</th>
                            <td className={'text-end'}>{model?.orderAmount.toString().withComma ?? '-'}</td>
                            <td className={'text-end'}>{model?.executeAmount.toString().withComma ?? '-'}</td>
                            <td className={'text-end'}>{model?.resultAmount.toString().withComma ?? '-'}</td>
                          </tr>
                          <tr>
                            <td className={'text-center'} colSpan={4}>両替レート</td>
                            <td className={'text-center'} colSpan={2}>{model.rate}</td>
                          </tr>
                          </tfoot>
                      )}
                    </Table>
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>

        {/* フッター */}
        <Footer>
          {model.isConfirmed || (
              <Button variant='outline-secondary'
                      className={'m-2'}
                      style={{width: 200}}
                      size={'sm'}
                      onClick={() => setModal('execute')}>
                実行登録
              </Button>
          )}

          {model.isConfirming && (
              <Button variant='primary'
                      className={'m-2'}
                      style={{width: 200}}
                      size={'sm'}
                      onClick={() => setModal('confirm')}>
                承認登録
              </Button>
          )}

        </Footer>

        {/* 確認モーダル */}
        <ConfirmModal show={modal === 'execute'}
                      confirmLabel={'登録する'}
                      onConfirm={() => {
                        setModal('');
                        handleExecute();
                      }}
                      onCancel={() => setModal('')}>

          両替実行を登録しますか？

          <div className={'mt-5'}>
            <Table className={'border-1 fs-6'}>
              <tbody>
              <tr>
                <th>実行日</th>
                <td className={'text-end'}>{model.executeDate || '----/--/--'}</td>
              </tr>
              <tr>
                <th>受渡日</th>
                <td className={'text-end'}>{model.processDate || '----/--/--'}</td>
              </tr>
              <tr>
                <th>両替金額</th>
                <td className={'text-end'}>{model.executeAmount.withComma}</td>
              </tr>
              <tr>
                <th>両替結果</th>
                <td className={'text-end'}>{model.resultAmount.withComma}</td>
              </tr>
              <tr>
                <th>両替レート</th>
                <td className={'text-end'}>{model.rate}</td>
              </tr>
              </tbody>
            </Table>
          </div>
        </ConfirmModal>


        {/* 確認モーダル */}
        <ConfirmModal show={modal === 'confirm'}
                      confirmLabel={'承認する'}
                      onConfirm={() => {
                        setModal('');
                        handleConfirm();
                      }}
                      onCancel={() => setModal('')}>

          両替内容を承認しますか？

          <div className={'mt-5'}>
            <Table className={'border-1 fs-6'}>
              <tbody>
              <tr>
                <th>実行日</th>
                <td className={'text-end'}>{model.executeDate || '----/--/--'}</td>
              </tr>
              <tr>
                <th>受渡日</th>
                <td className={'text-end'}>{model.processDate || '----/--/--'}</td>
              </tr>
              <tr>
                <th>両替金額</th>
                <td className={'text-end'}>{model.executeAmount.withComma}</td>
              </tr>
              <tr>
                <th>両替結果</th>
                <td className={'text-end'}>{model.resultAmount.withComma}</td>
              </tr>
              <tr>
                <th>両替レート</th>
                <td className={'text-end'}>{model.rate}</td>
              </tr>
              </tbody>
            </Table>
          </div>
        </ConfirmModal>
      </>
  );
}
