export function download(filename: string, data: Blob) {
  const a = document.createElement('a');

  a.setAttribute('style', 'display: none;')
  a.href     = URL.createObjectURL(data);
  a.download = filename;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

