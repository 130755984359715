export namespace PlazaI {
  interface Half {
    group: string
    item: string
    taxType: string
    taxRate: string
    customer: string
    project: string
    currency: string
    rate: string
    fc: string
    jpy: string
    tax: string
    reference: string
  }

  export interface Row {
    separator: boolean
    use: number
    date: string
    outline: string
    debit?: Half
    credit?: Half
    summary: string
  }

  /**
   * CSV変換（１行）
   *
   * @param data
   */
  export function csv(data: Row): string

  /**
   * CSV変換（複数行）
   *
   * @param list
   */
  export function csv(list: Row[]): string[];

  /**
   * CSV変換（実装）
   *
   * @param arg
   */
  export function csv(arg: any) {
    const isArray           = Array.isArray(arg),
          inputs: Row[]     = isArray ? arg : [arg],
          results: string[] = [] as string[]


    for (const input of inputs) {
      results.push([
        input.separator ? '*' : '',
        String(input.use),
        (new Date(input.date)).toYmd().replaceAll('-', '/'),
        input.outline,
        ..._half(input.debit),
        ..._half(input.credit),
        `"${input.summary}"`,
      ].join(","));
    }

    return isArray ? results : results[0];

    // 貸借半分処理
    function _half(input: Half | undefined): string[] {
      return [
        `"${input?.group ?? ''}"`,
        `"${input?.item ?? ''}"`,
        `"${input?.taxType ?? ''}"`,
        `"${input?.taxRate ?? ''}"`,
        `"${input?.customer ?? ''}"`,
        `"${input?.project ?? ''}"`,
        `"${input?.currency ?? ''}"`,
        `"${input?.rate ?? ''}"`,
        `"${input?.fc ?? ''}"`,
        `"${input?.jpy ?? ''}"`,
        `"${input?.tax ?? ''}"`,
        `"${input?.reference ?? ''}"`,
      ]
    }
  }
}

